export default {
    namespaced: true,
    state: {
        cartItem: [],
    },
    getters: {
        getCart: state => {
            return state.cartItem;
        },

    },
    mutations: {
        addCartItem(state, val) {
            state.cartItem = val;
        },

    },
    actions: {},
}
