export default {
    namespaced: true,
    state: {
        guid: "",
        customer: ""
    },
    getters: {
        getRole: state => {
            return state.guid;
        },
        getCustomer: state => {
            return state.customer;
        },
    },
    mutations: {
        addRole(state, val) {
            state.guid = val;
        },
        addCustomer(state, val) {
            state.customer = val;
        },
    },
    actions: {},
}
