export default {
    namespaced: true,
    state: {
        userBilgileri: {},
    },
    getters: {
        getUserBilgileri: state => {
            return state.userBilgileri;
        },
    },
    mutations: {
        SET_USER_BILGILERI(state, val) {
            state.userBilgileri = val
        },
    },
    actions: {},
}
