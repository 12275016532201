import Vue from 'vue'

// axios
import axios from 'axios'
import router from "@/router";

const URL_API = process.env.VUE_APP_URL_API;
const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: URL_API,
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
})
axiosIns.interceptors.response.use(async response => {
    return response;
}, async error => {
    if (error.response.status === 401) {
        await router.push({name: 'login'})
    }
});
Vue.prototype.$http = axiosIns

export default axiosIns
