import {getCokie, set} from '../Services/CookieJob'
import router from "@/router";
import axiosIns from "@/libs/axios";
import store from "@/store";
import {getItems} from "@/views/Services/CartUpdate";

const check = async (to,id) => {
    if (to.name==='login' && id) {
        axiosIns.get("MusteriPortaliGuidCheckV1/" + id).then(async (i) => {
            if (i.data["status"] == true) {
                set("guid", id, i.data["date"]);
                store.commit('loginStoreModule/SET_USER_BILGILERI', i.data)
            }
            await router.push({name: '/', params: {force: true}})
        });
    } else {
        let cookie = getCokie("guid");
        if (cookie == undefined) {
            await router.push({name: 'guid', params: {force: true}})
        } else {
            axiosIns.get("MusteriPortaliGuidCheckV1/" + cookie).then(async (i) => {
                if (i.data["status"] == true) {
                    await getItems(cookie);
                    set("guid", cookie, i.data["date"]);
                    store.commit('loginStoreModule/SET_USER_BILGILERI', i.data)
                }
            });
        }
        return cookie;
    }

}
export default check;