import Cookies from 'js-cookie'
import axiosIns from "@/libs/axios";

const set = (header, cokkie, day) => {
    Cookies.set(header, cokkie, {expires: day})
}
const getCokie = (header) => {
    let cookie = Cookies.get(header)
    return cookie;
}
const deleteCokie = () => {
    Cookies.remove('guid')
    return true;
}
const checkCookie = async () => {
    let cookie = Cookies.get("guid")
    if (cookie) {
        let kontrol = await axiosIns("GuidCheckV1/" + cookie)
        return kontrol.data;
    } else
        return false;
}
const cookieInfo = async () => {
    let cookie = Cookies.get("portalCookie")
    if (!cookie)
        return "<span> Sitemizde kullanıcı memnuniyetini ve güvenliğini artırmak amacıyla çerez (cookie)" +
            "konumlandırmaktayız. Bu çerez sistemimizin sizi tanıması ve kullanıcı adı ve şifresi girmeden giriş yapmanızı sağlamaktadır.</span>"
    return ""
}

export {set, getCokie, deleteCokie, checkCookie, cookieInfo};