import Vue from 'vue'
import VueRouter from 'vue-router'
import GuidCheck from "@/views/Services/GuidCheck";
import {getCokie, set} from "@/views/Services/CookieJob";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/hata',
            name: 'hata',
            component: () => import('@/views/Error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/teslimFisi/:id?',
            name: 'teslimFisi',
            component: () => import('@/views/TeslimFisi/TeslimFisi.vue'),
            meta: {
                pageTitle: 'Teslim Fişi',
                breadcrumb: [
                    {
                        text: 'Teslimatlar',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/login/:id?',
            name: 'login',
            component: () => import('@/views/Login/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/',
            name: 'login',
            component: () => import('@/views/Login/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/guid',
            name: 'guid',
            component: () => import('@/views/Error/Error404Guid.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('@/views/DashBoard/Dashboard.vue'),

        },
        {
            path: '/fiyat/',
            name: 'fiyat',
            component: () => import('@/views/FiyatIslemleri/FiyatTablosu.vue'),
            meta: {
                pageTitle: 'Ürün Fiyatları',
                breadcrumb: [
                    {
                        text: 'Fiyat Bilgileri',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/teslimatlar/',
            name: 'teslim',
            component: () => import('@/views/Teslimatlar/Teslimatlar.vue'),
            meta: {
                pageTitle: 'Teslim Edilen Ürünler',
                breadcrumb: [
                    {
                        text: 'Teslimatlar',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '*',
            redirect: 'Error-404',
        },
    ],
})

router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})
router.beforeEach(async (to, _, next) => {
    let cookie = getCokie("guid");
    if (cookie != undefined) await GuidCheck(to, to.params["id"])
    if (to.params["force"] == true) next();
    else next()
})
export default router
