import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import login from './login/index'
import cart from './cart/index'
import loginStoreModule from '@/views/Login/LoginStoreModule'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    login,
    cart,
    loginStoreModule
  },
  strict: process.env.DEV,
})
